






import Vue from "vue";
import coreApiClient from "@/services/apis/coreApiClient";
import userManager from "@/services/userManager";
import DataContainer from "@vuetifyx/common/DataContainer";
import {
  displayItemsWithName,
  displayItemsWithSku,
  displayItemWithName,
  displayItemWithSku,
  displayText,
  undisplayText,
} from "@/utils/marketItemDisplay";

export default Vue.extend({
  data() {
    return {
      self: this,
      tableOptions: null,
      importItems: [],
      insertDialogContainer: new DataContainer(),
    };
  },
  methods: {
    makeTableOptions() {
      const self = this;
      const self2 = this;
      return {
        attrs: {
          "item-key": "_id",
          "sort-by": "time",
          "sort-desc": true,
        },
        content: {
          name: "Quản lý chuyển kho",
          urlEnabled: true,
          search: {},
          displayFields: {
            _id: {
              text: "ID",
            },
            time: {
              text: "Thời gian",
              sortable: true,
              options: {
                filter: "dateTime",
              },
            },
            srcWarehouse: {
              text: "Kho nguồn",
              sortable: true,
              options: {
                sortBy: "srcWarehouseId",
                transform(value) {
                  return displayText(value, "name");
                },
              },
            },
            srcProducts: {
              text: "SP nguồn",
              options: {
                labels(_, item) {
                  return item.items.map((item) => {
                    const srcProduct = displayItemWithSku(item.srcProduct);
                    return `${(srcProduct && srcProduct.sku) || ""}`;
                  });
                },
                labelsSeparatedByNewLine: true,
              },
            },
            targetWarehouse: {
              text: "Kho đích",
              sortable: true,
              options: {
                sortBy: "targetWarehouseId",
                transform(value) {
                  return displayText(value, "name");
                },
              },
            },
            targetProducts: {
              text: "SP đích",
              options: {
                labels(_, item) {
                  return item.items.map((item) => {
                    const targetProduct = displayItemWithSku(item.targetProduct);
                    return `${(targetProduct && targetProduct.sku) || ""}`;
                  });
                },
                labelsSeparatedByNewLine: true,
              },
            },
            count: {
              text: "Số lượng",
              options: {
                labels(_, item) {
                  return item.items.map((item) => item.count);
                },
                labelsSeparatedByNewLine: true,
              },
            },
            name: {
              text: "Tên",
              sortable: true,
            },
            description: {
              text: "Mô tả",
            },
            createdByUser: {
              text: "User account chuyển kho",
              options: {
                subProp: "createdByUser.name",
              },
            },
            updatedTime: {
              text: "Tác động lần cuối",
              sortableable: true,
              options: {
                filter: "dateTime",
              },
            },
            lastUpdatedByUser: {
              text: "Tác động lần cuối bởi",
              options: {
                subProp: "lastUpdatedByUser.name",
                sortBy: "lastUpdatedByUserId",
              },
            },
            action: {},
          },
          filters: {
            filters: {
              _id: {
                attrs: {
                  label: "ID",
                },
                rules: {
                  equal_to_id: {},
                },
              },
              name: {
                attrs: {
                  label: "Name",
                },
                rules: {
                  match: {},
                },
              },
              srcWarehouseId: {
                type: "XAutocomplete",
                attrs: {
                  label: "Kho nguồn",
                  "item-value": "_id",
                  "item-text": "name",
                  xOptions: {
                    content: {
                      async itemsLoader() {
                        const { items } = await coreApiClient.call("warehouses", "findAll", {
                          payload: JSON.stringify({
                            limit: -1,
                          }),
                        });
                        return displayItemsWithName(items);
                      },
                    },
                  },
                },
                rules: {
                  equal_to: {},
                },
              },
              targetWarehouseId: {
                type: "XAutocomplete",
                attrs: {
                  label: "Kho đích",
                  "item-value": "_id",
                  "item-text": "name",
                  xOptions: {
                    content: {
                      async itemsLoader() {
                        const { items } = await coreApiClient.call("warehouses", "findAll", {
                          payload: JSON.stringify({
                            limit: -1,
                          }),
                        });
                        return displayItemsWithName(items);
                      },
                    },
                  },
                },
                rules: {
                  equal_to: {},
                },
              },
              time: {
                type: "XDateTimePicker",
                rules: {
                  greater_than_or_equal_to: {
                    attrs: {
                      label: "Ngày (bắt đầu)",
                    },
                  },
                  less_than_or_equal_to: {
                    attrs: {
                      label: "Ngày (kết thúc)",
                    },
                  },
                },
              },
              "items.srcProductId": {
                type: "XAutocomplete",
                attrs: {
                  label: "Sản phẩm nguồn",
                  "item-value": "_id",
                  "item-text": "sku",
                  multiple: true,
                  xOptions: {
                    content: {
                      async itemsSuggestor(search, value, limit) {
                        const { items } = await coreApiClient.call("products", "findAll", {
                          payload: JSON.stringify({
                            search,
                            limit,
                            filters: [
                              {
                                key: "_id",
                                operator: "in",
                                value,
                              },
                            ],
                          }),
                        });
                        return displayItemsWithSku(items);
                      },
                    },
                  },
                },
                rules: {
                  in: {},
                },
              },
              "items.targetProductId": {
                type: "XAutocomplete",
                attrs: {
                  label: "Sản phẩm đích",
                  "item-value": "_id",
                  "item-text": "sku",
                  multiple: true,
                  xOptions: {
                    content: {
                      async itemsSuggestor(search, value, limit) {
                        const { items } = await coreApiClient.call("products", "findAll", {
                          payload: JSON.stringify({
                            search,
                            limit,
                            filters: [
                              {
                                key: "_id",
                                operator: "in",
                                value,
                              },
                            ],
                          }),
                        });
                        return displayItemsWithSku(items);
                      },
                    },
                  },
                },
                rules: {
                  in: {},
                },
              },
            },
          },
          topActionButtons: {
            import: userManager.checkRole(["kt", "qlk", "vdl", "vd"]) && {
              attrs: {
                color: "default",
                dark: false,
              },
              content: {
                text: "Nhập Excel",
                icon: "mdi-upload",
              },
              target: {
                tooltip: { content: { text: "Nhập Excel" } },
                dialog: {
                  handlers: {
                    initialize() {
                      this.options.content.buttons.save.states.enabled = new DataContainer(false);
                    },
                  },
                  content: {
                    toolbar: {
                      title: "Nhập Excel",
                    },
                    content: {
                      type: "XForm",
                      makeAttrs(attrs, dialog) {
                        attrs.xModel = dialog.options.content.buttons.save.states.enabled;
                        attrs.xContext = dialog;
                        attrs.xData = new DataContainer({});
                        return attrs;
                      },
                      attrs: {
                        xOptions: {
                          content: {
                            sections: {
                              default: {
                                fields: {
                                  file: {
                                    type: "file",
                                    attrs: {
                                      label: "File",
                                      required: true,
                                    },
                                  },
                                },
                              },
                            },
                          },
                        },
                      },
                    },
                    buttons: {
                      save: {
                        content: {
                          text: "Tải lên",
                        },
                        states: {},
                        on: {
                          async xClick({ self }) {
                            const dialog = self.context();
                            const btn = dialog.context();
                            const dataTable = btn.context();
                            const form = dialog.contentContainer.value;
                            const data = form.getData();
                            self.loading.value = true;
                            const result = await dataTable.execute(
                              async () => {
                                return await coreApiClient.call("warehouseTransferCommands", "importPrepare", {}, data);
                              },
                              undefined,
                              {
                                disableLoading: true,
                                willRefresh: true,
                              }
                            );
                            self.loading.value = false;
                            if (result) {
                              dialog.hide();
                              self2.importItems = result;
                              self2.insertDialogContainer.value.show();
                            }
                          },
                        },
                      },
                    },
                  },
                },
              },
            },
            insert: userManager.checkRole(["kt", "qlk", "vdl", "vd"]) && {
              target: {
                dialog: {
                  attrs: {
                    width: "80%",
                  },
                  content: {
                    content: {
                      attrs: {
                        xOptions: {
                          content: {
                            fields: {
                              items: {
                                ext: {
                                  defaultValue() {
                                    return self.importItems;
                                  },
                                },
                              },
                            },
                          },
                        },
                      },
                    },
                  },
                  on: {
                    xHidden() {
                      self.importItems = [];
                    },
                  },
                  ext: {
                    container: self.insertDialogContainer,
                  },
                },
              },
            },
            export: userManager.checkRole(["kt", "qlk", "vdl", "vd"]) && {
              attrs: {
                color: "default",
                dark: false,
              },
              content: {
                text: "Xuất Excel",
                icon: "mdi-microsoft-excel",
              },
              on: {
                xClick({ self }) {
                  const dataTable = self.context();
                  let findAllOptions = dataTable.getFindAllOptions();
                  if (dataTable.model.value.length) {
                    findAllOptions = {
                      payload: JSON.stringify({
                        filters: [
                          {
                            key: "_id",
                            operator: "in",
                            value: dataTable.model.value.map((item) => item._id),
                          },
                        ],
                        limit: -1,
                      }),
                    };
                  }
                  coreApiClient.download("warehouseTransferCommands", `@/export`, findAllOptions);
                },
              },
            },
          },
          template: {
            itemActionButtons: {
              edit: userManager.checkRole(["kt", "qlk", "vdl", "vd"]) && {
                target: {
                  dialog: {
                    attrs: {
                      width: "80%",
                    },
                    ext: {
                      subTitleMaker: (item) => `${item.name} - ${new Date(item.time).toLocaleString()} }`,
                    },
                  },
                },
              },
              delete: userManager.checkRole(["admin"]) && {},
            },
          },
        },
        ext: {
          dataProvider: {
            async findAll(options) {
              const { items, count } = await coreApiClient.call("warehouseTransferCommands", "findAll", options);
              return [items, count];
            },
            async insert(item, dataTable) {
              item.marketId = dataTable.context().$route.params.marketId;
              return await coreApiClient.call("warehouseTransferCommands", "create", undefined, item);
            },
            async update(item) {
              return await coreApiClient.call(
                "warehouseTransferCommands",
                "update",
                {
                  id: item._id,
                },
                {
                  data: item,
                }
              );
            },
            async delete(item) {
              return await coreApiClient.call("warehouseTransferCommands", "delete", {
                id: item._id,
              });
            },
          },
          default: {
            defaultInputs: {
              name: {
                attrs: {
                  label: "Tên",
                },
                ext: {
                  colLength: 12,
                },
              },
              description: {
                type: "textLarge",
                attrs: {
                  label: "Mô tả",
                },
                ext: {
                  colLength: 12,
                },
              },
              time: {
                type: "XTimePicker",
                attrs: {
                  label: "Thời gian",
                  required: true,
                },
                ext: {
                  colLength: 4,
                },
              },
              srcWarehouseId: {
                type: "XAutocomplete",
                attrs: {
                  label: "Kho nguồn",
                  required: true,
                  "item-value": "_id",
                  "item-text": (item) => displayItemWithName(item, false, true),
                  xOptions: {
                    content: {
                      async itemsSuggestor(search, value, limit) {
                        const { items } = await coreApiClient.call("warehouses", "findAll", {
                          payload: JSON.stringify({
                            search: undisplayText(search),
                            limit,
                            filters: [
                              {
                                key: "_id",
                                operator: "equal_to",
                                value,
                              },
                            ],
                          }),
                        });
                        return items;
                      },
                    },
                  },
                },
                ext: {
                  colLength: 4,
                },
              },
              targetWarehouseId: {
                type: "XAutocomplete",
                attrs: {
                  label: "Kho đích",
                  required: true,
                  "item-value": "_id",
                  "item-text": (item) => displayItemWithName(item, false, true),
                  xOptions: {
                    content: {
                      async itemsSuggestor(search, value, limit) {
                        const { items } = await coreApiClient.call("warehouses", "findAll", {
                          payload: JSON.stringify({
                            search: undisplayText(search),
                            limit,
                            filters: [
                              {
                                key: "_id",
                                operator: "equal_to",
                                value,
                              },
                            ],
                          }),
                        });
                        return items;
                      },
                    },
                  },
                },
                ext: {
                  colLength: 4,
                },
              },
              items: {
                type: "XArrayInput",
                attrs: {
                  label: "Danh sách sản phẩm",
                  xOptions: {
                    content: {
                      itemLabel: (item) => {
                        const srcProduct = displayItemWithSku(item.srcProduct);
                        const targetProduct = displayItemWithSku(item.targetProduct);
                        return `${(srcProduct && srcProduct.sku) || ""} -> ${
                          (targetProduct && targetProduct.sku) || ""
                        }`;
                      },
                      itemProperties: {
                        srcProduct: {
                          type: "XAutocomplete",
                          attrs: {
                            label: "Sản phẩm nguồn",
                            required: true,
                            "item-value": "_id",
                            "item-text": (item) => displayItemWithSku(item, false, true),
                            "return-object": true,
                            clearable: true,
                            xOptions: {
                              content: {
                                async itemsSuggestor(search, value, limit) {
                                  const { items } = await coreApiClient.call("products", "findAll", {
                                    payload: JSON.stringify({
                                      search: undisplayText(search),
                                      limit,
                                      filters: [
                                        {
                                          key: "_id",
                                          operator: "equal_to",
                                          value,
                                        },
                                      ],
                                    }),
                                  });
                                  return items;
                                },
                              },
                            },
                          },
                          ext: {
                            onInput(value, form) {
                              form.decouratedData.srcProductId = value ? value._id : "";
                            },
                          },
                        },
                        targetProduct: {
                          type: "XAutocomplete",
                          attrs: {
                            label: "Sản phẩm đích",
                            required: true,
                            "item-value": "_id",
                            "item-text": (item) => displayItemWithSku(item, false, true),
                            "return-object": true,
                            clearable: true,
                            xOptions: {
                              content: {
                                async itemsSuggestor(search, value, limit) {
                                  const { items } = await coreApiClient.call("products", "findAll", {
                                    payload: JSON.stringify({
                                      search: undisplayText(search),
                                      limit,
                                      filters: [
                                        {
                                          key: "_id",
                                          operator: "equal_to",
                                          value,
                                        },
                                      ],
                                    }),
                                  });
                                  return items;
                                },
                              },
                            },
                          },
                          ext: {
                            onInput(value, form) {
                              form.decouratedData.targetProductId = value ? value._id : "";
                            },
                          },
                        },
                        count: {
                          type: "number",
                          attrs: {
                            label: "Số lượng",
                            required: true,
                          },
                        },
                      },
                      template: {
                        formOptions: {
                          content: {
                            colLength: 6,
                            ignoreOtherFields: false,
                          },
                        },
                      },
                    },
                  },
                },
              },
            },
            insertForm: userManager.checkRole(["kt", "qlk", "vdl", "vd"]) && {
              content: {
                fieldNames: ["name", "description", "time", "srcWarehouseId", "targetWarehouseId", "items"],
              },
            },
            editForm: userManager.checkRole(["kt", "qlk", "vdl", "vd"]) && {
              content: {
                fieldNames: ["name", "description", "time", "srcWarehouseId", "targetWarehouseId", "items"],
              },
            },
            delete: userManager.checkRole(["admin"]) && {
              confirmDisplayField: "_id",
            },
          },
        },
      };
    },
  },
  async created() {
    this.tableOptions = this.makeTableOptions();
  },
});
